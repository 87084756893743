.homepage-display {
    background: #F1F3ED;
    background: #fff;
}

.homepage-display .center-message {
    text-align: center;
    padding: 5% 0px;
    position: relative;
}

.homepage-display .center-message .right-illustration {
    position: absolute;
    top: -40px;
    right: 10px;
}

.homepage-display .center-message .left-illustration {
    position: absolute;
    bottom: -40px;
    left: 10px;
}

.homepage-display .center-message img {
    width: auto;
    height: 10rem;
}

.homepage-display .center-message.red-bg {
    background: #AA182C;
    background: #F9F3EA;
}

.homepage-display .center-message p {
    font-size: 2rem;
    line-height: 1.8;
    /* font-family: 'Dax Bold'; */
    margin-bottom: 20px;
    color: #AA182C;
}

.homepage-display .center-message.red-bg p {
    color: rgba(255, 255, 255, .8);
    color: var(--dark-blue-bg);
}


.homepage-display .center-message h4 {
    font-size: 2.5rem;
    line-height: 1.8;
    font-family: 'Dax Bold';
    margin-bottom: 20px;
    color: #fff;
    color: var(--dark-blue-bg);
}

.homepage-display .center-message .center-message-content {
    width: 60%;
    margin: 0px auto;
    display: block;
}

.homepage-display .center-message a {
    font-size: 1.6rem;
    line-height: 1.8;
    border-bottom: 2px solid var(--dark-blue-bg);
    /* font-family: 'Dax Bold'; */
    text-transform: uppercase;
    /* color: #AA182C;
    color: rgba(255, 255, 255, .8); */
    color: var(--dark-blue-bg);
    transition: border-color .2s ease-in-out, color .2s ease-in-out;
}

.homepage-display .center-message a:hover {
    border-color: #fff;
    color: #fff;
    text-decoration: none;
}

.homepage-display .center-message svg {
    height: 8rem;
    margin-bottom: 20px;
}

.homepage-display .center-message svg {
    height: 8rem;
    margin-bottom: 20px;
}

.homepage-display .center-message h5 {
    font-size: 1.6rem;
    margin-bottom: 20px;
    color: #AA182C;
}

.homepage-display .center-message.red-bg h5 {
    color: #fff;
    color: var(--dark-blue-bg);
}

/* center-message-cover */
.homepage-display .center-message-cover {
    border: 1px solid #ffff;
    border-left: none;
}

.homepage-display .center-message-extra {
    text-align: unset;
}

.homepage-display .center-message-extra p {
    text-align: left;
    font-family: 'Dax Regular';
    font-size: 1.4rem;
    color: var(--dark-blue-bg);
}

/* story-props */
.story-props.first-block {
    background: #F4EEE8;
    background: #F9F3EA;
    padding: 5% 0px;
}

.story-props .grid-2 {
    grid-gap: 20px;
}

.homepage-display .story-props .props-rect {
    border: 2px solid #ccc;
    height: 100%;
    width: 100%;
}

.homepage-display .story-props p {
    font-size: 1.7rem;
    line-height: 1.8;
    color: var(--dark-blue-bg);
}

.homepage-display .story-props h5 {
    font-size: 3rem;
    margin-bottom: 20px;
    line-height: 1.5;
    color: var(--dark-blue-bg);
    text-transform: uppercase;
}

.homepage-display .story-props a {
    color: #AA182C;
    font-size: 1.5rem;
    font-family: 'Dax Bold';
    margin-top: 30px;
    display: block;
}

.homepage-display .story-props.second-block {
    background: var(--dark-blue-bg);
    padding: 5% 0px;
}

.homepage-display .story-props.second-block h5 {
    /* color: #de7055; */
    color: var(--light-color);
}

.homepage-display .story-props.second-block p {
    /* color: #efd7c5; */
    color: var(--light-color);
}

.homepage-display .story-props p.first-paragraph {
    margin-bottom: 30px;
}

.homepage-display .story-props.second-block a {
    color: #de7055;
}

/* school-feature */

.homepage-display .school-feature {
    /* background: #FEFAF2; */
    /* background: #FFFBF3; */
    /* height: 500px; */
    display: flex;
    text-align: center;
    padding: 5% 0px;
}

.homepage-display .school-feature h2 {
    font-size: 4rem;
    /* width: 65%; */
    margin: 0px auto;
    display: block;
    margin-bottom: 30px;
}

.homepage-display .school-feature h4 {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.homepage-display .school-feature .button-flex {
    width: 40%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homepage-display .school-feature .contain {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.homepage-display .school-feature p {
    font-family: 'Space Grotesk';
    font-size: 2.2rem;
    line-height: 1.8;
    width: 60%;
    margin: 0px auto;
    display: block;
    margin-bottom: 30px;
}

/* .homepage-display .school-feature h2 {
    font-size: 6rem;
} */

.homepage-display .school-feature button {
    background: #111;
    padding: 15px 35px;
    border: 1px solid #111;
    border-radius: 50px;
    color: #fff;
    font-size: 1.4rem;
}

.homepage-display .school-feature .img-disp {
    position: absolute;
}

.homepage-display .school-feature .img-disp.left._1 {
    top: 0px;
    left: calc(10rem + 10%);
}

.homepage-display .school-feature .img-disp.left._2 {
    /* top: calc(10rem + 40px); */
    left: 3%;
    top: 0px;
}

.homepage-display .school-feature .img-disp.left._3 {
    /* top: calc(20rem + 80px); */
    left: calc(10rem + 10%);
    top: calc(10rem + 80px);
}

.homepage-display .school-feature .img-disp.left._4 {
    top: calc(20rem + 90px);
    left: 3%;
}

.homepage-display .school-feature .img-disp.left._5 {
    top: calc(20rem + 170px);
    left: calc(10rem + 5%);
}

.homepage-display .school-feature .img-disp.right._1 {
    top: 40px;
    top: 0px;
    right: calc(10rem + 10%);
}

.homepage-display .school-feature .img-disp.right._2 {
    /* top: calc(10rem + 40px); */
    right: 3%;
    top: 0px;
}

.homepage-display .school-feature .img-disp.right._3 {
    top: calc(10rem + 80px);
    right: calc(10rem + 10%);
}

.homepage-display .school-feature .img-disp.right._4 {
    top: calc(20rem + 90px);
    right: 3%;
}

.homepage-display .school-feature .img-disp.right._5 {
    top: calc(20rem + 170px);
    right: calc(10rem + 5%);
}

.homepage-display .school-feature .img-disp img {
    width: 18rem;
    height: 18rem;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
}

/* hero-sect */
.homepage-display .hero-sect {
    height: 600px;
    position: relative;
    overflow: hidden;
    background: url('../images/home.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.story-props.second-block img {
    object-position: center;
    object-fit: cover;
}

.homepage-display .hero-sect video {
    height: 56.25vw;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 10px);
    transform: translate3d(-50%, -50%, 10px);
    width: 100vw;
    z-index: 1;
    display: none;
}

.homepage-display .hero-sect h2 {
    /* position: absolute;
    bottom: 40px;
    left: 40px; */
    z-index: 2;
    color: #fff;
    font-size: 4rem;
}

.homepage-display .hero-sect-text {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    text-align: center;
}

.homepage-display .black-overlay {
    background: linear-gradient(to top right, rgba(0, 0, 0, .7), rgba(0, 0, 0, .3));
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
}

/* first-opening */
.first-opening {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #AA182C;
    width: 100%;
    z-index: 10;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.school-props-inner-images .grid-4 {
    grid-gap: 60px;
    margin-top: 10%;
}

.school-props-inner-images .grid-4 .props-img._1,
.school-props-inner-images .grid-4 .props-img._4 {
    margin-top: -30%;
}

.school-props-inner-images .grid-4 .props-img img {
    height: 35rem;
    object-fit: cover;
    object-position: center top;
    border-radius: 4px;
}

@media screen and (max-width: 720px) {
    .story-props .grid-2 {
        flex-direction: column-reverse;
        display: flex;
        padding: 15% 0px;
    }

    /* .homepage-display .story-props .props-rect {
        height: 30rem;
    } */


    .homepage-display .story-props .props-rect,
    .story-props.second-block img {
        height: 25rem;
        overflow: hidden;
        border: none;
    }

    .school-props-inner-images {
        display: none;
    }

    .homepage-display .story-props.second-block .grid-2 {
        flex-direction: column;
    }

    .homepage-display .center-message .center-message-content {
        width: 100%;
    }

    .homepage-display .school-feature {
        padding: 15% 0px;
    }

    .homepage-display .school-feature img {
        display: none;
    }

    .homepage-display .school-feature p {
        width: 80%;
        font-size: 1.6rem;
    }

    .homepage-display .school-feature h2 {
        font-size: 2rem;
    }

    .homepage-display .center-message.red-bg {
        padding: 15% 0px;
    }

    .homepage-display .center-message p {
        font-size: 1.4rem;
    }

    .homepage-display .center-message h4 {
        font-size: 1.8rem;
    }

    .homepage-display .center-message img {
        width: auto;
        height: 8rem;
    }

    .homepage-display .center-message h5 {
        font-size: 1.4rem;
    }

    .homepage-display .story-props p.first-paragraph {
        margin-bottom: 20px;
    }

    .homepage-display .hero-sect h2 {
        font-size: 3.5rem;
        line-height: 1.5;
    }
}