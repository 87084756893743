:root {
    --dark-blue-bg: #001233;
    --light-color: #F9F3EA;
    --light-green: #54DE62;
    --purple: #907aff;
    --dark-purple: #4A3E97;
    --red-txt: #FE5000;
    --red-color: #E30613;
    --text-color: #F9B32A;
    --blue: #0063DB;
}

@font-face {
    font-family: 'Gilroy Regular';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Regular.ttf');
}

@font-face {
    font-family: 'Gilroy Medium';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Medium.ttf');
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Bold.ttf');
}

@font-face {
    font-family: 'Circular Black';
    src: url('../fonts/Circular/CircularStd-Black.otf');
}

/* @font-face {
    font-family: 'Circular Medium';
    src: url('../fonts/Circular/CircularStd-Medium.otf');
} */

@font-face {
    font-family: 'Circular Medium';
    src: url('../fonts/Circular/CircularStd-Book.otf');
}

@font-face {
    font-family: 'Mikado';
    src: url('../fonts/mikado/MikadoBold_DEMO.otf');
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url('../fonts/Gilroy-FREE/Gilroy-FREE/Gilroy-Bold.ttf');
}

@font-face {
    font-family: 'TT Firs';
    src: url('../fonts/tt_firs_neue/TT\ Firs\ Neue\ Trial\ Bold.ttf');
}

@font-face {
    font-family: 'Gascogne Regular';
    src: url('../fonts/Gascogne-Regular.ttf');
}

@font-face {
    font-family: 'Sharp Sans Bold';
    src: url('../fonts/sharp-sans/SharpSansBold.otf');
}

@font-face {
    font-family: 'Monument Extended Ultrabold';
    src: url('../fonts/MonumentExtended/MonumentExtended-Ultrabold.otf');
}

@font-face {
    font-family: 'Dax Regular';
    src: url('../fonts/Dax/Dax\ Regular.ttf');
}

@font-face {
    font-family: 'Dax Bold';
    src: url('../fonts/Dax/Dax\ Bold.otf');
}

@font-face {
    font-family: 'Specter Black';
    src: url('../fonts/specter/Specter-Black.otf');
}

@font-face {
    font-family: 'Specter Medium';
    src: url('../fonts/specter/Specter-Medium.otf');
}

* {
    margin: 0px;
    padding: 0px;
    font-family: 'Gilroy Regular';
    font-family: 'Circular Medium';
    font-family: 'Dax Regular';
    font-family: 'Specter Medium';
    box-sizing: border-box;
}

body,
html {
    font-family: 'Gilroy Regular';
    font-family: 'Circular Medium';
    font-family: 'Dax Regular';
    font-family: 'Specter Medium';
    font-size: 10px;
    box-sizing: border-box;
    overflow-x: hidden;
    color: #212529;
    color: var(--dark-blue-bg) !important;
    /* background: #FAF9F7 !important; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Space Grotesk';
    font-weight: normal;
    font-family: 'Monument Extended Ultrabold';
    font-family: 'Specter Black';
}

h5,
h6 {
    font-family: 'Dax Bold';
    font-family: 'Specter Black';
    font-size: 1.6rem;
}

/* margin */
.mt_5 {
    margin-top: 5% !important;
    display: block;
}

.mt_10 {
    margin-top: 10% !important;
    display: block;
}

.mt_7 {
    margin-top: 7% !important;
    display: block;
}

/* navigation */
.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 3%;
    box-shadow: 0px 0px 2px 3px rgba(35, 95, 104, 0.1);
    /* position: fixed; */
    z-index: 1000;
    /* background: #2F255C; */
    background: var(--dark-blue-bg);
}

.navigation .mobile-only svg {
    stroke: #fff;
    fill: #fff;
}

.navigation.relativeNav {
    position: relative;
}

.navigation .inner-nav {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    grid-gap: 50px;
}

.navigation .inner-nav img {
    width: auto;
    height: 4.5rem;
}

.navigation.fix {
    position: fixed;
    top: 0px;
    width: 100%;
    background: var(--dark-blue-bg);
    /* background: #05192D; */
    z-index: 1000;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
}

.navigation.fix svg {
    stroke: #fff;
    fill: #fff;
    color: #fff;
}

.navigation h1 {
    margin-bottom: 0px;
    color: #111;
    font-size: 2.4rem;
    word-spacing: -1px;
}

.navigation.fix h1 {
    color: #fff;
}

.navigation ul {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    margin: 0px;
}

.navigation ul li {
    list-style: none;
}

.navigation ul li.colored {
    background: var(--red-color);
    padding: 12px 35px;
    border-radius: 3px;
    border-radius: 50px;
}

.navigation ul li a {
    font-size: 1.4rem;
    color: var(--light-color);
    text-decoration: none;
}

.navigation ul li.colored a {
    color: var(--light-color);
}

.navigation.fix ul li a {
    color: var(--light-color);
}

.navigation.fix ul li a {
    color: var(--light-color);
}

.navigation ul li a.active,
.navigation.fix ul li a.active {
    color: var(--red-txt);
}

.navigation ul li.colored a:active {
    color: rgb(249, 243, 234) !important;
    color: green !important;
}

.navigation svg {
    margin-left: 3%;
}

.navigation .logo-bar {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 4px;
    grid-gap: 10px;
}

.navigation .logo-bar h3 {
    font-size: 1.3rem;
    line-height: 1.5;
    margin-bottom: 0px;
    color: rgb(0, 18, 51);
}

.navigation .logo-bar h3 span {
    display: block;
    font-size: 1.4rem;
    font-family: 'Specter Black';
}

/* layout */
.desktop-only {
    display: block !important;
}

.mobile-only {
    display: none !important;
}

.center {
    text-align: center;
}

.flex-2 {
    display: flex;
    justify-content: space-between;
}

.colored-bg {
    background: #0F172A;
    padding: 40px 0px;
    text-align: center;
}

.colored-bg p {
    color: #c7d2fe;
    font-size: 1.6rem;
}

.colored-bg h2 {
    color: #c7d2fe;
    font-size: 3.5rem;
    margin-bottom: 10px;
}

/* grid */
.contain {
    padding: 0px 3%;
}

.container {
    padding: 0px 6% !important;
    max-width: unset !important;
}

.center-div {
    width: 40%;
    margin: 0px auto;
    display: block;
    text-align: center;
}

.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.grid-5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
}

.grid-4 img {
    width: 100%;
    height: 100%;
}

.grid-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.grid-2 img,
.grid-4 img,
.grid-3 img {
    width: 100%;
    height: 100%;
}

/* prop-display */
.prop-display {
    background: #170CB1;
    padding: 5% 0px;
}

.prop-display .grid-2 {
    align-items: center;
}

.prop-display .grid-2 h1 {
    color: #fff;
    font-size: 3.5rem;
    width: 85%;
    margin-bottom: 20px;
}

.prop-display .grid-2 button {
    background: #D8A459;
    padding: 14px 35px;
    border: 1px solid #D8A459;
    font-size: 1.4rem;
    color: #fff;
    border-radius: 3px;
    margin-top: 20px;
}

.prop-display .grid-2 p {
    font-size: 1.6rem;
    color: #fff;
    line-height: 1.6;
}

.prop-grid-bg {
    background: #D12C70;
    width: 40rem;
    height: 40rem;
    margin: 0px auto;
    display: block;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}

.prop-grid-bg img {
    position: absolute;
    width: 100%;
    height: auto;
}

/* testimonial */
.testimonial {
    background: #fff;
}

.testimonial .feature-block {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
}

.testimonial img {}

/* footer */
.footer {
    background: var(--dark-blue-bg);
    padding: 5% 0px;
    padding-bottom: 0px;
    margin-top: 5%;
}

.footer.margin {
    margin-top: 5%;
}

.footer.noMargin {
    margin-top: 0%;
}

.footer ul li a,
.footer p,
.footer a {
    color: var(--light-color);
    text-decoration: none;
    font-size: 1.3rem;
}

.footer ul li {
    line-height: 2.4;
    list-style: none;
}

.footer h4 {
    color: #fff;
    color: var(--light-color);
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.footer p,
.footer a {
    line-height: 2.4;
}

.footer ion-icon {
    font-size: 2.5rem;
    color: #fff;
    margin-right: 20px;
}

.footer .first-sect p {
    line-height: 1.8;
}

.footer .first-sect button {
    background: #D8A459;
    padding: 14px 35px;
    border: 1px solid #D8A459;
    font-size: 1.4rem;
    color: #fff;
    border-radius: 3px;
    margin-top: 20px;
}

.footer .footer-grid {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
}

.footer .footer-grid-2 {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.footer .contact-list {
    display: flex;
    flex-direction: row;
    grid-gap: 30px;
}

.footer .contact-list li.sec a {
    color: rgba(220, 220, 250, 0.2);
    font-size: 1.8rem;
    line-height: 1.8;
}

.footer .social-icon {
    background: var(--light-color);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer ul {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
}

.footer .icon-showcase {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.footer .icon-showcase svg {
    width: 55%;
    height: auto;
}

.footer p.ims-story {
    width: 70%;
    line-height: 1.8;
    font-size: 1.3rem;
}

.footer .sec-footer ul {
    display: flex;
    flex-direction: row;
    grid-gap: 30px;
    margin-bottom: 0px;
}

.footer .sec-footer ul a,
.footer .sec-footer p {
    font-size: 1.3rem;
    color: rgba(220, 220, 250, .7);
    margin-bottom: 0px;
}

.footer .sec-footer {
    background: #0B392A;
    background: var(--dark-blue-bg);
    padding: 20px 0px;
    padding-top: 20px;
    margin-top: 30px;
    border-top: 1px solid rgba(220, 220, 250, 0.7);
}

/* hero-display */
.hero-display {
    background: linear-gradient(to bottom right, rgba(220, 220, 250, 1), rgba(220, 220, 250, .2), rgba(255, 255, 255, 1));
    background: #10162F;
    background: #fff;
    padding-bottom: 3%;
    padding-bottom: 0%;
    padding-top: 2%;
}

.hero-display .grid-2 {
    align-items: center;
    grid-gap: 20px;
}

.hero-display .grid-2 h1 {
    font-size: 3.5rem;
    width: 95%;
    margin-bottom: 20px;
    /* color: #fff; */
}

.hero-display .grid-2 p.youtube-impact {
    font-size: 1.5rem;
    margin-top: 50px;
}

.hero-display .grid-2 p {
    font-size: 2rem;
    line-height: 1.6;
    /* color: #fff; */
}

.hero-display .grid-2 img {
    /* background: #E6E6E6; */
    width: 75%;
    height: auto;
    margin: 0% auto;
    display: block;
}

.hero-display .grid-2 .grid-3 {
    grid-gap: 10px;
}

.hero-display button {
    background: #D8A459;
    padding: 14px 35px;
    border: 1px solid #D8A459;
    font-size: 1.4rem;
    color: #fff;
    border-radius: 3px;
    margin-top: 20px;
}

.hero-props {
    background: #E6E8E3;
    padding: 5% 0px;
}

.hero-props h3 {
    font-size: 4rem;
    margin-bottom: 4%;
}

.red-tag {
    color: #D12C70;
    margin-bottom: 0px;
    font-size: 1.6rem;
}

.hero-props .props-sect-cover {
    background: #fff;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, .1);
    padding: 20px 10px;
    text-align: center;
    border-radius: 3px;
}

.hero-props .grid-4 {
    grid-gap: 10px;
}

.hero-props .props-circle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 0px auto;
    display: block;
    margin-bottom: 20px;
}

.hero-props .props-sect-cover p {
    font-size: 1.5rem;
    line-height: 1.6;
}

.hero-props .props-circle.props-1 {
    background: #DF3689;
}

.hero-props button {
    background: transparent;
    padding: 10px 25px;
    border-radius: 4px;
    border: 2px solid #071A2B;
    font-size: 1.5rem;
}

.hero-props .props-text {
    border: 2px solid rgba(0, 0, 0, .1);
    padding: 30px 25px;
    border-radius: 3px;
}

.hero-props .props-text h4 {
    font-size: 2rem;
}

.hero-props .props-text p {
    font-size: 2rem;
    line-height: 1.8;
    margin-bottom: 0px;
}

.hero-summary {
    background: #160BA8;
}

/* button */
.button-flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.btn-blue {
    background: var(--blue);
    padding: 13px 35px;
    border: 1px solid var(--blue);
    font-size: 1.4rem;
    color: #fff;
    border-radius: 4px;
    border-radius: 50px;
    display: block;
    width: max-content;
}

.btn-blue-blank {
    background: transparent;
    padding: 13px 35px;
    border: none;
    font-size: 1.4rem;
    color: var(--blue);
    border-radius: 4px;
    display: block;
    width: max-content;
}

.btn-purple-border {
    background: transparent;
    padding: 13px 35px;
    border: 1px solid var(--purple);
    font-size: 1.4rem;
    color: var(--dark-purple);
    border-radius: 50px;
    display: block;
    width: max-content;
}

.btn-yellow {
    background: #D8A459;
    padding: 14px 35px;
    border: 1px solid #D8A459;
    font-size: 1.4rem;
    color: #fff;
    border-radius: 3px;
    margin-top: 20px;
}

.btn-red {
    background: var(--red-color);
    padding: 14px 35px;
    border: 1px solid var(--red-color);
    font-size: 1.4rem;
    color: #fff;
    border-radius: 3px;
    margin-top: 20px;
}

.btn-white {
    background: #fff;
    padding: 14px 35px;
    border: 1px solid #fff;
    font-size: 1.4rem;
    color: #36383A;
    border-radius: 50px;
    margin-top: 20px;
    font-family: 'Circular Black';
}

.btn-purple {
    background: var(--purple);
    padding: 13px 35px;
    border: 1px solid var(--purple);
    font-size: 1.4rem;
    color: #fff;
    border-radius: 50px;
    /* font-family: 'Circular Black'; */
}

.btn-default {
    background: transparent;
    border: 2px solid #ccc;
    border-radius: 50px;
    color: #ccc;
    font-size: 1.4rem;
    padding: 10px 25px;
}

.btn-purple.curve {
    border-radius: 50px;
}

.btn-array {
    grid-gap: 10px;
    display: flex;
}

/* lane */
.lane-blue {
    background: #C7D2FE;
    padding: 10px;
    display: flex;
    justify-content: center;
}

.lane-blue p {
    font-size: 1.3rem;
    margin-bottom: 0px;
}

/* .hero-display .grid-3 */

/* form */

label {
    font-size: 1.3rem;
}

.errorMessage {
    font-size: 1.3rem;
    line-height: 1.6;
    color: #FF0000;
}

.form_flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.form_group,
.form-group {
    margin-bottom: 20px !important;
}

.width-60 {
    width: 60%;
}

.width-60.center {
    margin: 0px auto;
    display: block;
}

/* about us */

.about_hero {
    padding: 5% 0px;
    padding-top: 15%;
    position: relative;
    overflow: hidden;
}

.about_hero {
    height: 50rem;
}

.about_hero h5 {
    color: #fff;
    font-size: 1.4rem;
    margin-bottom: 20px;
}

.about_hero h2 {
    color: #fff;
    font-size: 5.5rem;
    width: 55%;
}

.about_hero_story {
    background: #38C8C9;
    padding: 70px 0px;
}

.about_hero_story p {
    font-size: 2.8rem;
    width: 80%;
    margin-bottom: 0px;
    color: #fff;
}

.about_hero_story .line-block {
    display: block;
    width: 10px;
    height: 30px;
    position: relative;
    background: #fff;
}

.about_hero_story .about_hero_display {
    display: flex;
    align-items: flex-start;
}

.about_main_story {
    width: 70%;
    margin: 0px auto;
    display: block;
}

.about_main_story img {
    width: 100%;
    height: 50rem;
    object-position: center;
    object-fit: cover;
    margin-bottom: 30px;
}

.about_main_story .center {
    text-align: center;
}

.about_main_story .center h3 {
    width: 75%;
    margin: 0% auto;
    display: block;
    font-size: 2rem;
    line-height: 1.5;
}

.about_main_story p.principal_speech {
    font-size: 1.5rem;
    display: block;
    margin-bottom: 30px;
    line-height: 1.8;
}

.about_main_story p.main_about_text {
    font-size: 1.5rem;
    width: 80%;
    margin: 0px auto;
    display: block;
    line-height: 1.8;
}

.about_hero h1 {
    font-size: 4.5rem;
    width: 55%;
    margin-bottom: 10px;
}

.about_main_story h4 {
    /* width: 80%;
    margin: 0px auto; */
    display: block;
    margin-bottom: 0px;
}

.about_main_story p {
    margin-bottom: 0px;
}

.about_main_story .avatar-cover {
    /* width: 30%;
    height: auto;
    border-radius: 50%;
    background: red;
    overflow: hidden;
    text-align: center; */
}

.about_main_story .avatar-cover-sect {
    display: grid;
    grid-template-columns: 1fr 6fr;
    align-items: center;
    width: max-content;
    width: 60%;
    grid-gap: 20px;
}

.about_main_story .avatar-cover-section {
    width: 80%;
    margin: 0px auto;
}

.about_main_story .avatar-img {
    width: 100%;
    height: auto;
    background: red;
    border-radius: 50%;
    margin-bottom: 0px;
    /* width: auto; */
}

.about_hero h1 span {
    font-family: 'TT Firs';
    color: var(--purple);
}

.about_hero svg {
    position: absolute;
    right: 0px;
    width: 40%;
    height: auto;
    top: 0px;
}

.about_us .dark-blue-bg {
    background: var(--dark-blue-bg);
    padding: 50px 0px;
}

.about_us .dark-blue-bg img {
    width: 80%;
    height: auto;
    margin: 0px auto;
    display: block;
}

.about_us .dark-blue-bg p {
    font-size: 1.4rem;
    color: #fff;
    line-height: 1.6;
}

.about_us .dark-blue-bg h2 {
    color: #fff;
    margin-bottom: 5px;
    font-size: 1.6rem;
}

.about_us .dark-blue-bg .grid-2 .grid-2 {
    grid-gap: 20px;
    row-gap: 40px;
}

.about_us .dark-blue-bg .grid-2 {
    align-items: center;
}

.about_us .dark-blue-bg h1 {
    color: #fff;
    margin-bottom: 40px;
    font-size: 2.5rem;
}

.about_us .about-prop-img-cover {
    background: var(--light-green);
    height: 60px;
    width: 60px;
    border-radius: 3px;
    margin-bottom: 10px;
}

.about_us .testimonial {
    padding: 5% 0px;
}

.about_us .testimonial p.testimonial-content {
    font-size: 1.8rem;
    line-height: 1.8;
    margin-bottom: 20px;
}

.about_us .testimonial p.testimonial-author {
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 20px;
}

.testimonial-control {
    display: flex;
    grid-gap: 20px;
}

.testimonial-control ion-icon {
    font-size: 3rem;
}

.testimonial img {
    height: 45rem;
    width: 80%;
    object-fit: cover;
    object-position: center;
    border-radius: 3px;
}

.testimonial img.quote {
    height: 5rem;
    width: auto;
    margin-bottom: 20px;
}

.testimonial .grid-2 {
    align-items: center;
}


/* notes */
.notes-page {
    padding-top: 10%;
}

.notes-block {
    background: lime;
}

/* contact */
.contact-hero {
    display: flex;
    align-items: center;
}

.contact-bg-white {
    padding: 5% 20px;
    border-radius: 4px;
    background: #fff;
}

.contact-hero .grid-2 {
    align-items: center;
}

.contact-hero .grid-2 h2 {
    color: #fff;
    font-size: 3rem;
    width: 80%;
    line-height: 1.6;
}

.contact-hero .grid-2 h5 {
    color: #fff;
}

.contact-hero .grid-2 p {
    color: #fff;
    font-size: 1.6rem;
    line-height: 1.8;
}

.contact-hero .contact-form {
    width: 70%;
    float: right;
}

.white-sect {
    height: 50rem;
}

.testimonial-display .grid-4 {
    grid-gap: 20px;
}

.testimonial-display .grid-4 img {
    height: 30rem;
    object-position: top;
    object-fit: cover;
    margin-bottom: 10px;
}

.testimonial-display .grid-4 p {
    font-size: 1.4rem;
}

.testimonial-sect {
    background: #10523D;
    height: 30rem;
    width: 80%;
    margin: 0px auto;
    display: block;
    border-radius: 20px;
    margin-bottom: 10%;
}


@media screen and (max-width: 720px) {

    body {
        background-color: #faf9f7 !important;
    }

    .contain,
    .container {
        padding: 0px 1.5% !important;
    }

    .mt_5 {
        margin-top: 15% !important;
    }

    .desktop-only {
        display: none !important;
    }

    .mobile-only {
        display: block !important;
    }

    /* .navigation {
        padding: 20px 3%;
    } */

    .navigation .inner-nav h1 {
        font-size: 2rem;
        font-weight: bolder;
        letter-spacing: -.5;
        word-spacing: -.5;
    }

    .hero-display .grid-2 {
        display: flex;
        flex-direction: column;
    }

    .hero-display {
        padding: 15% 0px;
    }

    .hero-display .grid-2 img {
        margin: unset;
    }

    .hero-display img {
        width: 100%;
        height: auto;
    }

    .hero-display .grid-2 h1 {
        font-size: 4rem;
        margin-bottom: 10px;
    }

    .hero-display .grid-2 button {
        width: 100%;
    }

    .hero-display .grid-2 p {
        font-size: 1.6rem;
    }

    .hero-props .grid-4 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .feature-display .grid-4 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .colored-bg .grid-4 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    /* sec-display */
    .sec-display .sec-display-grid {
        display: grid;
        grid-template-columns: 1fr 5fr;
    }

    .sec-display .sec-display-grid p {
        font-size: 1.6rem;
        line-height: 1.6;
        margin-bottom: 0px;
    }

    .sec-display .sec-display-grid h4 {
        font-size: 1.8rem;
    }

    .sec-display {
        background: #F7F7FC;
        padding: 5% 0px;
    }

    h2.new-page-title {
        font-size: 1.6rem;
        color: #44307a;
        margin-bottom: 20px;
    }

    .sec-display-grid-cover {
        display: flex;
        grid-gap: 30px;
        flex-direction: column;
    }

    .sec-display .sec-display-grid svg {
        width: 40px;
        height: 40px;
    }

    /* sec-props */
    .sec-props {}

    .sec-props img {
        width: 100%;
        height: 100%;
        margin-bottom: 30px;
        border-radius: 4px;
        margin-top: 15%;
    }

    .sec-props h2.new-page-title {
        margin-bottom: 0px;
    }

    .sec-props h3 {
        font-size: 2.4rem;
    }

    .sec-props p {
        font-size: 1.6rem;
    }

    /* inner-sec-props */
    .inner-sec-props {
        margin-top: 15%;
    }

    .inner-sec-props p {
        font-size: 1.6rem;
        margin-bottom: 0px;
    }

    .inner-sec-props .inner-sec-grid {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        margin-bottom: 30px;
    }

    .inner-sec-props .inner-sec-grid p {
        margin-bottom: 0px;
    }

    .inner-sec-props .inner-sec-grid svg {
        margin-bottom: 15px;
    }

    .inner-sec-props .inner-sec-grid h4 {
        margin-bottom: 10px;
        font-size: 1.8rem;
    }

    .blue-cover {
        background: #7145D6;
        padding: 10% 0px;
    }

    .blue-cover h3 {
        font-size: 2.5rem;
        color: #fff;
        margin-bottom: 20px;
    }

    .blue-cover button {
        background: #44307A;
        border: 1px solid #44307A;
        padding: 14px 25px;
        width: 100%;
        color: #fff;
        font-size: 1.4rem;
        border-radius: 4px;
    }

    /* footer */
    .footer .footer-grid-2.sec-2 {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 30px;
    }

    .footer p.ims-story {
        width: 100%;
    }

    .footer .icon-showcase {
        width: 60%;
        margin-bottom: 20px;
    }

    .footer .sec-footer .footer-grid-2 {
        flex-direction: column;
    }

    .footer .sec-footer ul {
        flex-wrap: wrap;
        grid-gap: 10px;
    }

    .ant-drawer-body {
        background: #faf9f7;
    }

    .mobile-nav ul li {
        display: block;
        margin: 0px;
        border-bottom: .1px solid #b3b3b3;
        width: 100%;
        margin-bottom: 10px;
        list-style: none;
    }

    .mobile-nav ul li a {
        color: #111;
        padding: 15px 10px;
        display: block;
        width: 100%;
        line-height: 1;
    }

    .ant-drawer-header {
        display: none !important;
    }

    .mobile-nav .mobile-list {
        margin-bottom: 0px;
        margin-top: 5%;
    }

    .mobile-nav .mobile-header {
        border-bottom: 1px solid #b3b3b3;
        display: flex;
        justify-content: space-between;
        padding: 20px 10px 10px;
    }

    .ant-drawer-content-wrapper {
        width: 100% !important;
    }

    .ant-drawer-body {
        padding: 0px !important;
    }

    .footer {
        margin-top: 15%;
        padding: 15% 0px;
    }

    .footer .contact-list,
    .footer .footer-grid-2 {
        display: flex;
        flex-direction: column;
        grid-gap: 30px;
    }

    .footer .contact-list {
        grid-gap: 20px;
        row-gap: 0px;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .footer ul {
        align-items: baseline;
    }
    .navigation .inner-nav img {
        height: 3rem;
    }
    .navigation .logo-bar h3 {
        font-size: 1rem;
    }
    .navigation .logo-bar h3 span {
        font-size: 1rem;
    }
}

/* sec-navigation */
.sec-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 3%;
}

.sec-navigation ul {
    display: flex;
    grid-gap: 40px;
    margin-bottom: 0px;
    align-items: center;
}

.sec-navigation ul li {
    list-style: none;
}

.sec-navigation ul li a {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.6);
}

.sec-navigation ul li a.active-nav {
    color: rgba(0, 0, 0, 1);
    font-family: 'Gilroy Bold';
}

.sec-navigation .sec-logo {
    width: 100px;
    height: 50px;
    background: var(--blue);
    border-radius: 4px;
}

.sec-navigation ul li a.btn-blue {
    color: #fff;
}

.sec-navigation.fix {
    position: fixed;
    top: 0px;
    width: 100%;
    background: #fff;
    z-index: 10;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, .1);
}