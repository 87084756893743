.plan_bg {
    background: #111;
    width: 100%;
    height: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact .plan_bg h3 {
    font-size: 3rem;
    color: #fff;
}

.contact .grid-2 {
    grid-gap: 50px;
}

.contact .grid-2 p {
    font-size: 1.4rem;
    line-height: 1.8;
}

.contact .contact_div .sticky_social ion-icon {
    font-size: 3rem;
}

.contact .contact_div .sticky_social div {
    display: flex;
    grid-gap: 10px;
    margin-top: 5%;
}

.contact .sticky_compartment {
    margin-top: 3%;
}

.contact h3 {
    /* font-size: 2rem; */
}


/* first-content-display */
.first-content-display {
    /* height: 55rem; */
    background: #fff;
    padding: 5% 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.first-content-display .first-content-text {
    width: 50%;
    margin: 0% auto;
    display: block;
}

.first-content-display .first-content-text p {
    font-size: 1.8rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.first-content-display .first-content-text button {
    margin-top: 0px;
}

.first-content-display .first-content-text h1 {
    font-size: 3rem;
    margin: 0px auto;
    margin-bottom: 10px;
    width: 70%;
}


/* homepage */
.feature-display {
    /* background: #E2E2DA; */
    /* padding: 5% 0px; */
}

.feature-display .grid-4 {
    grid-gap: 20px;
}

.feature-display .center-div p {
    font-size: 1.4rem;
}

.feature-display .center-div h2 {
    font-size: 2.5rem;
    margin-bottom: 70px;
}

.feature-display .feature-block {
    background: #fff;
    text-align: center;
    padding: 30px 5px;
    border-radius: 4px;
}

.feature-display .feature-block p {
    font-size: 1.4rem;
    line-height: 1.6;
}

.feature-display .feature-block h3 {
    font-size: 1.8rem;
    line-height: 1.6;
    margin-bottom: 15px;
}

.feature-circle {
    width: 80px;
    height: 80px;
    background: #D12C70;
    border-radius: 50%;
    margin: 0px auto;
    display: block;
    margin-bottom: 15px;
    overflow: hidden;
}

.feature-2 {
    background: #170CB1;
}

.feature-3 {
    background: #D8A459;
}

.blog-showcase .blog-cover img {
    width: 100%;
    height: 150px;
}

.blog-showcase .blog-cover p {
    font-size: 1.4rem;
    line-height: 1.6;
    margin-bottom: 0p;
}

.blog-showcase .blog-cover h3 {
    font-size: 1.6rem;
    /* width: 70%; */
    margin-bottom: 5px;
    line-height: 1.3;
}

.blog-showcase .blog-cover {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, .5);
}

.blog-showcase .blog-cover-text {
    padding: 10px 5px;
    padding-bottom: 0px;
}

.form-page button {
    margin-top: 0px;
}

input {
    font-family: 'Specter Medium' !important;
    font-size: 1.3rem !important;
}

.ant-input-affix-wrapper.ant-input-password input {
    height: 4rem;
}

.form-page .form-page-detail {
    width: 50%;
    margin: 0px auto;
    display: block;
    margin-top: 5%;
}

.form-page label {
    margin-bottom: 0px;
    line-height: 1.8;
    font-size: 1.4rem;
}

.form-page button {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 5.5rem;
}

.form-page a {
    display: block;
}

.form-page .block-link {
    border: 2px solid var(--purple);
    border-radius: 8px;
    text-align: center;
    padding: 13px;
    font-size: 1.4rem;
    color: var(--purple);
    margin-bottom: 20px;
}

.form-page .extra-link {
    text-align: center;
    font-size: 1.4rem;
    color: #111;
}

.form-page .extra-link span {
    color: var(--purple);
}

.form-page .forgot {
    font-size: 1.3rem;
}

.form-page .center p {
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 40px;
}

.form-page .center h4 {
    font-size: 2.6rem;
}

.form-page .center {
    text-align: left;
}

.form-page .pheripheral-buttons a {
    /* color: rgb(0, 90, 184); */
    color: #001233;
    color: #D8A459;
    color: var(--red-color);
    text-decoration: underline;
    font-size: 1.4rem;
    margin-bottom: 20px;
}

.form-page .pheripheral-buttons p {
    font-size: 1.4rem;
}

.form-page .pheripheral-buttons p a {
    display: inline-block;
    font-size: 1.4rem;
    margin-bottom: 0px;
}

.form-page button.btn-purple {
    border-radius: 4px;
    height: 5.5rem;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.form-page .testimonial {
    background: unset;
    position: absolute;
    bottom: 30px;
}

.form-page .testimonial-author {
    display: flex;
    grid-gap: 10px;
    align-items: center;
}

.form-page .testimonial-avatar {
    background: #8C83C2;
    height: 60px;
    width: 60px;
    border-radius: 50%;
}

.form-page .testimonial-author p {
    margin-bottom: 0px;
}

.form-page .testimonial-author h5 {
    color: #fff;
    margin-bottom: 0px;
}

.form-page .testimonial-author h5 span {
    display: block;
}

.form-page .testimonial-author p {
    font-size: 1.4rem;
    line-height: 1.8;
    color: rgba(253, 253, 253, .8);
}

.form-page .testimonial-message {
    font-size: 1.3rem;
    line-height: 1.8;
    color: rgba(253, 253, 253, .8);
}


/* mini-display */
.mini-display .hero-display {
    background: url('../images/banner.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 10rem;
}

.mini-display .top-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.mini-display .top-links ul li {
    display: inline-block;
    margin-right: 40px;
}

.mini-display .top-links ul li:last-child {
    margin-right: 0px;
}

.mini-display .top-links ul li a {
    font-size: 1.4rem;
    color: #111;
}

.mini-display .top-links ul li a.active {
    background: #7167D8;
    padding: 12px 25px;
    border-radius: 50px;
    color: #fff;
}

.mini-display .about-display .grid-sec-2 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 40px;
}

.mini-display .about-display .grid-sec-2 p {
    font-size: 1.4rem;
    line-height: 1.8;
}

.mini-display .about-display .grid-sec-2 h4 {
    font-size: 2.2rem;
}

.mini-display .about-center-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mini-display .about-center-content p {
    font-size: 1.5rem;
    line-height: 1.6;
}

.mini-display .about-center-content h3 {
    font-size: 2.2rem;
}

.mini-display .about-display .grid-sec-2 img {
    width: 100%;
    height: 100%;
    object-position: center top;
    object-fit: cover;
}


/* abt-hero */
.abt-hero {
    position: relative;
    height: 350px;
    background: linear-gradient(to right, rgba(0, 0, 0, .8), rgba(0, 0, 0, .2)), url('../images/banner.jpg');
    background-position: top;
    background-size: cover;
    display: flex;
    align-items: center;
}

.abt-hero h5 {
    color: #fff;
    font-size: 2rem;
    text-transform: uppercase;
}

.abt-hero h2 {
    color: #fff;
    font-size: 4rem;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.about-page p {
    font-size: 1.6rem;
    line-height: 1.8;
}


.about-page .body-content p {
    margin-bottom: 30px;
}

.about-page .body-content h5 {
    margin-bottom: 10px;
    border-bottom: 2px solid #212529;
    width: max-content;
    font-size: 1.6rem;
}

.about-page .body-content h3 {
    font-size: 2.3rem;
    letter-spacing: 2px;
    margin-bottom: 20px;
}

.about-page .body-content {
    padding-top: 5% !important;
}

.event-block {
    display: flex;
    grid-gap: 10px;
}

.event-block .date-block {
    border: 2px solid #111;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
}

.event-block .date-block h3 {
    font-size: 2rem;
    margin-bottom: 0px;
    font-family: 'Dax Bold';
}

.about-page .body-content .event-block p {
    font-size: 1.4rem;
    line-height: 1.8;
    margin-bottom: 0px;
}

.event-block h4 {
    font-size: 1.8rem;
    line-height: 1.8;
    font-family: 'Dax Bold';
    margin-bottom: 0px;
}

.about-page.calendar .grid-2 {
    grid-gap: 30px;
    row-gap: 40px;
}

.about-page.contact-page .black-bg {
    background: #000000;
    height: 100%;
    width: 100%;
    padding: 10% 15px;
}

.about-page.contact-page .contact-flex-display {
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.about-page.contact-page .contact-flex-display .main-content-display {
    padding: 10% 0px;
}

.about-page.contact-page .black-bg p,
.about-page.contact-page .black-bg h3 {
    color: #fff;
}

.about-page.contact-page .black-bg h3 {
    font-size: 1.6rem;
    font-family: 'Dax Bold';
    margin-bottom: 20px;
}

.about-page.contact-page .black-bg p {
    font-size: 1.4rem;
    line-height: 1.8;
}

.about-page.contact-page .black-bg span,
.about-page.contact-page .black-bg a {
    color: #E30613;
}

.black-bg-content {
    border-bottom: 1px solid #F6F4EF;
    margin-bottom: 50px;
}

.about-page.contact-page .black-bg p.contact-inner-lead {
    font-size: 1.4rem;
    color: rgba(253, 253, 253, .8);
    font-family: 'Dax Bold';
}

.about-page.contact-page .black-bg .sticky_social ion-icon {
    font-size: 2.6rem;
}

.about-page.contact-page .black-bg .sticky_social div {
    display: flex;
    grid-gap: 10px;
    margin-top: 5%;
    margin-bottom: 5%;
}

.about-page.contact-page .black-bg .sticky_social .icon-cover {
    background: #fff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.excursion-display .grid-4 {
    grid-gap: 10px;
}

.center-empty-element {
    height: 30rem;
    width: 40%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.excursion-display .grid-4 img {
    height: 25rem;
    width: 100%;
    object-fit: cover;
    object-position: bottom center;
    border-radius: 4px;
    overflow: hidden;
}

.contact-page .main-content-display p {
    margin-bottom: 40px;
}

@media screen and (max-width: 720px) {
    .contact .grid-2 {
        display: block;
    }

    .form-page .form-page-detail {
        width: 98%;
        margin-top: 15%;
    }

    .form-page .center h4 {
        font-size: 2.3rem;
    }

    .form-page .center p {
        margin-bottom: 20px;
        font-size: 1.3rem;
    }

    .form-page .pheripheral-buttons p,
    .form-page .pheripheral-buttons a {
        font-size: 1.3rem;
    }

    .form-page button {
        margin: 0px;
    }

    .form-page label {
        font-size: 1.3rem;
    }

    .about-page p,
    .homepage-display .story-props p {
        font-size: 1.4rem;
    }

    .about-page .body-content h3,
    .homepage-display .story-props h5 {
        font-size: 1.8rem;
        line-height: 1.4;
        margin-bottom: 20px;
    }

    .homepage-display .story-props h5 {
        line-height: 1.8;
        margin-bottom: 10px;
    }

    .about-page .body-content h5 {
        width: 80%;
        border-bottom: none;
        line-height: 1.3;
    }

    .about-page .body-content {
        padding-top: 15% !important;
    }

    .abt-hero {
        height: 200px;
    }

    .abt-hero h2 {
        font-size: 2.5rem;
    }

    .abt-hero h5 {
        font-size: 1.4rem;
    }

    .excursion-display .grid-4 img {
        height: 15rem;
    }

    .excursion-display .grid-4 {
        grid-template-columns: repeat(2, 1fr);
    }

    .about-page.contact-page .contact-flex-display {
        display: flex;
        flex-direction: column-reverse;
    }

    .contact-page .main-content-display p {
        margin-bottom: 20px;
    }
}